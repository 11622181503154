import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

  id: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,    ) { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

 }
