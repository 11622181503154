import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { InstructionDialogComponent } from './components/instruction-dialog/instruction-dialog.component';

@Injectable()
export class UtilMethods {

  constructor(
    private ngxService: NgxUiLoaderService,
    public dialog: MatDialog
  ) {

  }

  startForeground() {
    this.ngxService.start();
  }

  stopForeground() {
    this.ngxService.stop();
  }

  objectToQuerystring(obj) {
    return Object.keys(obj).filter((key) => obj[key] != undefined && obj[key] != '').reduce((str, key, i) => {
      var delimiter, string, val;
      delimiter = (i === 0) ? '?' : '&';
      if (Array.isArray(obj[key])) {
        key = encodeURIComponent(key);
        var arrayVar = obj[key].reduce((str, item) => {
          val = encodeURIComponent(JSON.stringify(item));
          return [str, key, '=', val, '&'].join('');
        }, '');
        return [str, delimiter, arrayVar.trimRight('&')].join('');
      } else {
        key = encodeURIComponent(key);
        val = encodeURIComponent(JSON.stringify(obj[key]));
        return [str, delimiter, key, '=', val].join('');
      }
    }, '');
  }

  openDialog(ref, finalFunction, extraData = {}, message = 'Do you want to continue to the new plan',
    cancelText = 'No', affirmationText = 'Yes'): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message,
        cancelText,
        affirmationText,
      }
    });
    dialogRef.afterClosed().subscribe(result => finalFunction(ref, result, extraData));
  }

  openInstructionDialog(ref, finalFunction, extraData = {}, message = 'Do you want to continue to the new plan',
    cancelText = 'Ok', affirmationText = 'Yes'): void {
    const dialogRef = this.dialog.open(InstructionDialogComponent, {
      width: '700px',
      data: {
        message,
        cancelText,
        affirmationText,
      }
    });
    dialogRef.afterClosed().subscribe(result => finalFunction(ref, result, extraData));
  }

}
