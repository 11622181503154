import { Injectable } from '@angular/core';


declare global {
  interface Window { dataLayer: any; }
}

window.dataLayer = window.dataLayer || {};

@Injectable({
  providedIn: 'root'
})
export class GoogleManagerService {

  private state: any = {};

  constructor() { }

  public reset(): void {
    this.state = {};
  }

  public changeState(key: string, value: any): void {
    this.state = {
      ...this.state,
      [key]: value
    };
  }

  public push(event_name='prospect_journey_event') {
    console.log(this.state, 'state');
    window.dataLayer.push({
      'event': event_name,
      'mcart':this.state});
  }
}
