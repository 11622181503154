import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { PlansComponent } from './pages/plans/plans.component';
import { GcpNotFoundComponent } from './pages/gcp-not-found/gcp-not-found.component';
import { ThankreturnComponent } from './pages/thankreturn/thankreturn.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'marketplace',
    pathMatch: 'full'
  },
  {
    path: 'gcp',
    redirectTo: 'gcp-marketplace',
    pathMatch: 'full'
  },
  {
    path: 'marketplace',
    loadChildren: './pages/marketplace/marketplace.module#MarketPlaceModule'
  },
  {
    path: 'influencers',
    loadChildren: './pages/influencers/influencers.module#InfluencersModule'
  },
  {
    path: 'retail-stores',
    loadChildren: './pages/retail-stores/retail-stores.module#RetailStoresModule'
  },
  {
    path: 'thanks',
    component: ThanksComponent
  },
  {
    path: 'thankreturn',
    component: ThankreturnComponent
  },
  {
    path: 'gcp-not-found',
    component: GcpNotFoundComponent
  },
  {
    path: 'gcp-marketplace',
    loadChildren: './pages/gcp/gcp.module#GCPModule',
    pathMatch: 'prefix'
  },
  {
    path: 'plans',
    component: PlansComponent
  },
  {
    path: '**',
    redirectTo: 'marketplace'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
