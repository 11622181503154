export enum Plans {
  plan1 = 'plan1',
  plan2 = 'plan2',
  plan3 = 'plan3',
  plan4 = 'plan4'
}

export interface IMCartPlan {
  tier: string;
  planName: string;
  stores: string;
  shoppers: string;
  influencers: string;
  admin: string;
  productCategory: string;
  mCarts: string;
  planId: string;
  skus: string;
  minGMV: number;
  maxGMV: number;
  priceRange: string;
}

export const mCartPlan: IMCartPlan[] = [
  {
    tier: '1',
    planId: '-1',
    planName: '1 Month Pilot Plan',
    stores: '5',
    shoppers: '100K',
    influencers: '50',
    admin: '1',
    productCategory: '100',
    mCarts: '100',
    skus: '-1',
    minGMV: -1,
    maxGMV: -1,
    priceRange: '-1'
  },
  {
    tier: '2',
    planId: '-1',
    planName: 'Standard',
    stores: '30',
    shoppers: '1M',
    influencers: '200',
    admin: '5',
    productCategory: '300',
    mCarts: '1500',
    skus: '-1',
    minGMV: -1,
    maxGMV: -1,
    priceRange: '-1'
  },
  {
    tier: '3',
    planId: '-1',
    planName: 'Enterprise',
    stores: '30',
    shoppers: '3M',
    influencers: '1000',
    admin: '12',
    productCategory: '800',
    mCarts: '5000',
    skus: '-1',
    minGMV: -1,
    maxGMV: -1,
    priceRange: '-1'
  },
  {
    tier: '4',
    planId: '-1',
    planName: 'Unlimited',
    stores: 'Unlimited',
    shoppers: 'Unlimited',
    influencers: 'Unlimited',
    admin: 'Unlimited',
    productCategory: 'Unlimited',
    mCarts: 'Unlimited',
    skus: '-1',
    minGMV: -1,
    maxGMV: -1,
    priceRange: '-1'
  }
];


// For Google Users

export const mCartPlanGcp: IMCartPlan[] = [
  {
    tier: '1',
    planId: 'pilot',
    planName: 'Pilot',
    stores: '1',
    shoppers: '10k',
    influencers: '50',
    admin: '1',
    productCategory: '100',
    mCarts: '100',
    skus: '10000',
    minGMV: 10000,
    maxGMV: 1000000,
    priceRange: '$54k'
  },
  {
    tier: '2',
    planId: 'standard',
    planName: 'Standard',
    stores: '10',
    shoppers: '100k',
    influencers: '200',
    admin: '3',
    productCategory: '300',
    mCarts: '1500',
    skus: '500000',
    minGMV: 10000,
    maxGMV: 10000000,
    priceRange: '$90k'
  },
  {
    tier: '3',
    planId: 'enterprise',
    planName: 'Enterprise',
    stores: '25',
    shoppers: '1M',
    influencers: '1000',
    admin: '5',
    productCategory: '500',
    mCarts: '5000',
    skus: '1000000',
    minGMV: 10000,
    maxGMV: 100000000,
    priceRange: '$174k'
  },
];