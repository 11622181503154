import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss',
    '../gcp/styles/main.scss']
})
export class PlansComponent implements OnInit {

  isProductSelected = true;
  isPricingSelected = false;
  navOpen = false;
  year = new Date().getFullYear();

  showingSocialIcons = true;

  constructor(config: NgbCarouselConfig,
    private route: ActivatedRoute) {

    // customize default values of carousels used by this component tree
  }

  ngOnInit() {
  }

  showSocialIcons() {
    this.showingSocialIcons = true;
  }
  hideSocialIcons() {
    this.showingSocialIcons = false;
  }

  post(planID) {
    const obj = {
      'x-gcp-marketplace-token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJlNDE5Yzg4NTNiZmY2ZGJjNWY5NmRjZmIyZDc3N2RhNTk0MTllOGIiLCJ0eXAiOiJKV1QifQ.eyJzdWIiOiJFLUVBQkItQzYzRS1GNUM2LUI5MjgiLCJhdWQiOiJhZmZpbGlhdGVuZXR3b3JrLm1jYXJ0LmlvIiwiaWF0IjoxNTY0NTExNDQwLCJleHAiOjE1NjQ1MTE3NDAsImlzcyI6Imh0dHBzOi8vd3d3Lmdvb2dsZWFwaXMuY29tL3JvYm90L3YxL21ldGFkYXRhL3g1MDkvY2xvdWQtY29tbWVyY2UtcGFydG5lckBzeXN0ZW0uZ3NlcnZpY2VhY2NvdW50LmNvbSJ9.TVLdgo3ue5_Rbj7MLf3nsc52rrwoV_DFCcmHPqTA3iAZrtIk8NbUlZbWtoZvCS5jyhgAPeHHdR9XcDpshy9al1_f7UvX1bgbyYNqbv8VUt755KggBDRWOo-6e44AQmJvHOCcfLBPmDNkgGxFGL_RZaWiqj501KzaxUph6WzzUNSKt23fC3Z7qJV-LE_veYNe8VGQsMfNofpiD8lc95QcurIu07TmCyn5ZzwvjiN45S0orZ9qAEtBme_uBCL--3S1EdcghQRXOgLHa4FNvp_P8wj-iY6a3xMUOjvlxf8yCD_0eHKK6bXeE28OGXbqQ-xCT3OiU2FPZc5dZjDRmktKpw',
      planID: planID
    };

    const url = `${window.location.origin}/gcp-marketplace`;
    let mapForm = document.createElement('form');
    mapForm.target = '_blank';
    mapForm.method = 'POST'; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      let mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }
}
