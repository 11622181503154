import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankreturn',
  templateUrl: './thankreturn.component.html',
  styleUrls: ['./thankreturn.component.scss']
})
export class ThankreturnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.localStorage.setItem('facenote-user','true')
  }

}
