import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';  // RxJS 6 syntax
import { environment } from '../../environments/environment.prod';

@Injectable()
export class DataService {

    private _api_base_url = 'https://api.markit.place/';
    constructor(private http: HttpClient) { }

    public getMarketFormData(storeQueryOptions): Observable<any[]> {

        let stores = this.http.get(`${environment._api_base_url}catalog_vendors.json?mav_user_api_key=${environment.USER_API_KEY} 
            &sort_by=${storeQueryOptions.sort}&order=${storeQueryOptions.order}&page=${storeQueryOptions.page}&page_size=${storeQueryOptions.size}`);
        let categories = this.http.get(`${environment._api_base_url}/api/admin/store_top_categories?mav_user_api_key=${environment.USER_API_KEY}&sort_by=${storeQueryOptions.sort}&order=${storeQueryOptions.order}&page=${storeQueryOptions.page}&page_size=${storeQueryOptions.size}`);
        let influencers = this.http.get(`${environment._api_base_url}/api/users?mav_user_api_key=${environment.USER_API_KEY}&sort_by=${storeQueryOptions.sort}&page=${storeQueryOptions.page}&page_size=${storeQueryOptions.size}`);


        return forkJoin([stores, categories, influencers]);
    }

    public getStores(storeQueryOptions): Observable<any> {
        return this.http.get(`${environment._api_base_url}/catalog_vendors.json`);
    }

    public getMarketplaces(): Observable<any> {
        const url = `${environment._api_base_url}/api/marketplaces`;
        return this.http.get(url);
    }

    public getCategoriesByParentCategory(storeQueryOptions, parentCategories): Observable<any[]> {
        let promises = [];
        parentCategories.forEach((cat) => {
            promises.push(this.http.post(`${this._api_base_url}/child_categories`, {
                code: cat.code
            }))
        })

        return forkJoin(promises);
    }

    public getInfluencers(influencersQueryOptions): Observable<any> {
        let url = `${environment._api_base_url}/api/users?&filter_by=global_influencers`;
        if(influencersQueryOptions.page){
            url += `&page=${influencersQueryOptions.page}`;
        }
        if(influencersQueryOptions.page_size){
            url += `&page_size=${influencersQueryOptions.page_size}`;
        }
        if (influencersQueryOptions.featured_carts_count) {
            url += `&featured_carts_count=${influencersQueryOptions.featured_carts_count}`;
        }
        if (influencersQueryOptions.followers_count) {
            url += `&followers_count=${influencersQueryOptions.followers_count}`;
        }
        if (influencersQueryOptions.marketplaces_count) {
            url += `&marketplaces_count=${influencersQueryOptions.marketplaces_count}`;
        }
        if (influencersQueryOptions.interests && influencersQueryOptions.interests.length > 0) {
            url += `&interests=${JSON.parse(influencersQueryOptions.interests)}`;
        }
        return this.http.get(url);
    }

    public getInfluencersIntrests(): Observable<any> {
        return this.http.get(`${environment._api_base_url}/api/influencer_interests?page_size=100`);
    }

    public sortAlphabatically(data) {
        return data.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }

}
