import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ArchwizardModule } from 'angular-archwizard';

@Component({
  selector: 'app-instruction-dialog',
  templateUrl: './instruction-dialog.component.html',
  styleUrls: ['./instruction-dialog.component.scss']
})
export class InstructionDialogComponent implements OnInit {

  message = 'Instruction';
  cancelText = 'Ok';
  affirmationText = 'Yes';

  src: string;
  currStep: number = 1;

  next: boolean;
  back: boolean;
  ok: boolean;

  constructor(public dialogRef: MatDialogRef<InstructionDialogComponent>,
    archwizardModule : ArchwizardModule,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {if (data) {
      if (data.message) { this.message = data.message; }
      if (data.cancelText) { this.cancelText = data.cancelText; }
      if (data.affirmationText) { this.affirmationText = data.affirmationText; }
    } }

  ngOnInit() {
    this.loadImage();
  }

  loadImage(){
    if(this.currStep === 1){
      this.src = "../../../../assets/gcp/gcp-step-1.jpg";
      this.next = true;
      this.back = false;
      this.ok = false;
    }else if(this.currStep === 2){
      this.src = "../../../../assets/gcp/gcp-step-2.jpg";
      this.next = true;
      this.back = true;
      this.ok = false;
    }else if(this.currStep === 3){
      this.src = "../../../../assets/gcp/gcp-step-3.jpg";
      this.next = true;
      this.back = true;
      this.ok = false;
    }else if(this.currStep === 4){
      this.src = "../../../../assets/gcp/gcp-step-4.jpg";
      this.next = true;
      this.back = true;
      this.ok = false;
    }else if(this.currStep === 5){
      this.src = "../../../../assets/gcp/gcp-step-5.jpg";
      this.next = false;
      this.back = true;
      this.ok = true;
    }else{
      console.log('error');
      return;
    }
  }

  onPressCancel(): void {
    this.dialogRef.close();
  }

  onNextClick(){
    this.currStep ++;
    this.loadImage();
  }

  onBackClick(){
    this.currStep--;
    this.loadImage();
  }

}
