import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  message = 'Are you sure you want to continue this action?';
  cancelText = 'No';
  affirmationText = 'Yes';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      if (data.message) { this.message = data.message; }
      if (data.cancelText) { this.cancelText = data.cancelText; }
      if (data.affirmationText) { this.affirmationText = data.affirmationText; }
    }
  }

  ngOnInit() { }

  onPressCancel(): void {
    this.dialogRef.close();
  }

}
