import { Component, OnInit, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GcpHomeComponent } from 'src/app/pages/gcp/gcp-home/gcp-home.component';
import { MessageService } from '../../messageService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {

  navOpen = false;
  subOpen = false;
  isProductSelected = true;
  showPricingDropdown = false;
  isGCPModule = false;
  currentModuleName = '';

  constructor(
    private location: Location,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    if (window.location.href.includes('gcp-marketplace')) {
      this.isGCPModule = true;
    } else {
      this.isGCPModule = false;
    }

    this.messageService.getMessage().subscribe(message => {
      if (message && message.type === 'ModuleUpdated') {

        if (message.value && message.value.name) {
          this.currentModuleName = message.value.name;
          if (message.value.name === 'gcp-marketplace') {
            this.isGCPModule = true;
          } else {
            this.isGCPModule = false;
          }
        }
      }
    });
  }

  ngOnChanges() {
    if (window.location.href.includes('gcp-marketplace')) {
      this.isGCPModule = true;
    } else {
      this.isGCPModule = false;
    }

  }


  toggleNav() {
    this.subOpen = false;
    this.navOpen = !this.navOpen;
    setTimeout(() => {
      const elems = document.getElementsByClassName('visible');
      if (elems && elems.length > 0) {
        (elems[0] as any).style.width = '87%';
      }
    }, 300);
  }

  toggleSub() {
    this.navOpen = false;
    this.subOpen = !this.subOpen;
  }

  openPricingDropdown() {
    this.closeSub();
    this.showPricingDropdown = !this.showPricingDropdown;
  }

  closeNav() {
    this.navOpen = false;
  }

  closeSub() {
    this.subOpen = false;
  }

  scrollToTag(tag) {
    if (this.isGCPModule && tag) {
      this.messageService.sendMessage({ type: 'GCPHome_Scroll_To_Tag', value: { tag } });
    }
  }

  openModuleInfo() {
    const currLocation = window.location.href;
    if (currLocation.includes('influencers/info')) {
      window.open(`https://markit.place/how-it-works/influencers/`);
    } else if (currLocation.includes('retail-stores')) {
      window.open(`https://markit.place/how-it-works/retailers/`);
    }
  }

  openModule(path) {
    if (!window.location.href.includes(path)) {
      this.router.navigate([path]);
    }
  }
  hideOverlay(){
    this.subOpen = false;
    this.navOpen = false;
  }

}
