import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gcp-not-found',
  templateUrl: './gcp-not-found.component.html',
  styleUrls: ['./gcp-not-found.component.scss',
    '../thanks/thanks.component.scss']
})
export class GcpNotFoundComponent implements OnInit {

  id: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  }

}
